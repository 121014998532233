/**
 * Class element
 * Mediasocial component
 * Display the list of media social
 */
/* Import section */
import { StaticQuery, graphql } from "gatsby"
import React, { Component } from "react"
import MediasocialList from "./mediasociallist"
import "../styles/components/mediasocial.scss"

/* Declaration main class with StaticQuery */
class Mediasocial extends Component {
  render () {
    const allsocialmedia = this.props.socialmedia.acfPageOptions.businessSocialMedia

    return (
      <ul className="list-media-social">
        {allsocialmedia.map(itemsocial => (
            <MediasocialList key={itemsocial.nameSocialMedia} list={itemsocial} />
          )
        )}
      </ul>
    )
  }
}

/* Export main class with StaticQuery */
export default props => (
  <StaticQuery
    query={graphql`
      query {
        wpPage(slug: {eq: "options-website"}) {
          acfPageOptions {
            businessSocialMedia {
              nameSocialMedia
              linkSocialMedia {
                url
              }
            }
          }
        }
      }
    `}
      render={({ wpPage }) => <Mediasocial socialmedia={wpPage} {...props} />}
  />
)
