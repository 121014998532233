/**
 * Const element
 * Footer component
 * Display a footer
 */
/* Import section */
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import Ctabutton from "./ctabutton"
import Logo from "./logo"
import Mediasocial from "./mediasocial"
import {LoginUrl} from "../utils/apputils"
import "../styles/components/footer.scss"

/* Declaration function with StaticQuery */
const Footer = () => {
  const data = useStaticQuery(
    graphql`
      query {
        options: wpPage(slug: {eq: "options-website"}) {
          acfPageOptions {
            mobileAppTitle
            mobileAppDescription
            appleAppLogo {
              localFile {
                childImageSharp {
                  gatsbyImageData (layout: CONSTRAINED, width: 200)
                }
              }
            }
            appleLinkApp
            googleAppLogo {
              localFile {
                childImageSharp {
                  gatsbyImageData (layout: CONSTRAINED, width: 200)
                }
              }
            }
            googleLinkApp
          }
        }

      }
    `
  )

  return (
    <footer className="footer">
      <section className="separator"></section>
      <section className="mobile-app">
        <h2 className="title-level-2">{data.options.acfPageOptions.mobileAppTitle}</h2>
        <div dangerouslySetInnerHTML={{ __html:data.options.acfPageOptions.mobileAppDescription}} />
        <div className="flex-logo-mobileapp">
          <a className="link-mobileapp" href={data.options.acfPageOptions.appleLinkApp} target="_blank" rel="noopener noreferrer" aria-label="Download Apple Application"><GatsbyImage image={data.options.acfPageOptions.appleAppLogo.localFile.childImageSharp.gatsbyImageData} alt="Apple Store logo for download PortalConnect App" /></a>
          <a className="link-mobileapp" href={data.options.acfPageOptions.googleLinkApp} target="_blank" rel="noopener noreferrer" aria-label="Download Google Application"><GatsbyImage image={data.options.acfPageOptions.googleAppLogo.localFile.childImageSharp.gatsbyImageData} alt="Google Store logo for download PortalConnect App"/></a>
        </div>
        <div className="shape-footer-left"></div>
        <div className="shape-footer-right"></div>
      </section>
      <section className="main-footer">
        <div className="box-infos">
          <div className="logo-footer">
            <Logo color="white" />
          </div>
          <nav key="secondnav" className="second-nav">
            <ul key="second-list" className="second-list">
              <li className="cta-login"><Ctabutton ctaLabel="Login" ctaLink={LoginUrl} otherStyle="stroke-format" extLink={true} target={false}/></li>
              {/* 07-11-23 hided by mc  <li className="sublist virtual-care"><span>Virtual Care</span>
                <ul className="second-sublist">
                  <li><Link to="/virtual-care/">How it Works</Link></li>
                  <li><Link to="/virtual-care/quality-care/">Quality Care</Link></li>
                  <li><Link to="/virtual-care/plans/">Plans</Link></li>
                </ul>
              </li> */}
              <li className="sublist"><span>Help Center</span>
                <ul className="second-sublist">
                  <li><Link to="/faq/">FAQs</Link></li>
                  <li><Link to="/learning-center/">Learning Center</Link></li>
                </ul>
              </li>
              <li><Link to="/blog/">Blog</Link></li>
              <li><Link to="/about-us/">About</Link></li>
              <li><Link to="/contact/">Contact</Link></li>
            </ul>
          </nav>
        </div>
        <div className="box-bottom">
          <div className="box-copyright">
            <p>©{new Date().getFullYear()} PortalConnect by Hello Health Inc.</p>
            <p><Link to={`/terms-and-conditions/`}>Terms & Conditions</Link> | <Link to={`/privacy-policy/`}>Privacy Policy</Link></p>
          </div>
          <div className="box-social">
            <Mediasocial />
          </div>
        </div>
      </section>
    </footer>
  )
}

/* Export function */
export default Footer
