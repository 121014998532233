/**
 * Class element
 * MediasocialList component
 * Display the list of media social to sibling manageHover on each li
 */
/* Import section */
import React, { Component } from "react"
import Facebook from "../images/social/facebook.inline.svg"
import Facebookhover from "../images/social/facebookhover.inline.svg"
import Instagram from "../images/social/instagram.inline.svg"
import Instagramhover from "../images/social/instagramhover.inline.svg"
import Twitter from "../images/social/twitter.inline.svg"
import Twitterhover from "../images/social/twitterhover.inline.svg"
import Youtube from "../images/social/youtube.inline.svg"
import Youtubehover from "../images/social/youtubehover.inline.svg"

class MediasocialList extends Component {

  constructor(props) {
    super(props)
    this.state = {
      hoverSocialMedia: false
    }
  }

  manageHover(e, boolStateHover){
    this.setState({
      hoverSocialMedia: boolStateHover
    })
  }

  render () {
    const itemsocial= this.props.list

    if(itemsocial.nameSocialMedia === 'Facebook') {
      return (
        <li
          onMouseEnter={() => this.manageHover(this, true)}
          onMouseLeave={() => this.manageHover(this, false)}
          role="presentation"
        >
          <a href={itemsocial.linkSocialMedia.url} className="link-social" target="_blank" rel="noopener noreferrer" aria-label="Link to" >
            {this.state.hoverSocialMedia ? <Facebookhover />:<Facebook />}
          </a>
        </li>
      )
    }else if(itemsocial.nameSocialMedia === 'Twitter') {
      return (
        <li
          onMouseEnter={() => this.manageHover(this, true)}
          onMouseLeave={() => this.manageHover(this, false)}
          role="presentation"
        >
          <a href={itemsocial.linkSocialMedia.url} className="link-social" target="_blank" rel="noopener noreferrer" aria-label="Link to" >
            {this.state.hoverSocialMedia ? <Twitterhover />:<Twitter />}
          </a>
        </li>
      )
    }else if(itemsocial.nameSocialMedia === 'Instagram') {
      return (
        <li
          onMouseEnter={() => this.manageHover(this, true)}
          onMouseLeave={() => this.manageHover(this, false)}
          role="presentation"
        >
          <a href={itemsocial.linkSocialMedia.url} className="link-social" target="_blank" rel="noopener noreferrer" aria-label="Link to" >
            {this.state.hoverSocialMedia ? <Instagramhover />:<Instagram />}
          </a>
        </li>
      )
    }else if(itemsocial.nameSocialMedia === 'Youtube') {
      return (
        <li
          onMouseEnter={() => this.manageHover(this, true)}
          onMouseLeave={() => this.manageHover(this, false)}
          role="presentation"
        >
          <a href={itemsocial.linkSocialMedia.url} className="link-social" target="_blank" rel="noopener noreferrer" aria-label="Link to" >
            {this.state.hoverSocialMedia ? <Youtubehover />:<Youtube />}
          </a>
        </li>
      )
    }
  }
}


/* Export function */
export default MediasocialList