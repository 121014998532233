/**
 * Const with params element
 * Section CTA footer component
 * Display a section with a CTA
 */
/* Import section */
import React from "react"
import Ctabutton from "./ctabutton"
import Iconcontactblue from "../images/icons/iconcontactblue.inline.svg"
import Iconfaqblue from "../images/icons/iconfaqblue.inline.svg"
import Iconlearnblue from "../images/icons/iconlearnblue.inline.svg"
import Iconvcspeakdoctor from "../images/icons/iconvcspeakdoctor.inline.svg"
import "../styles/components/sectionctafooter.scss"

/* Declaration function */
const Sectionctafooter = ({ typeCta, titleCta, labelCta, classCta }) => {
  return (
    <section className={`section-cta-footer ${classCta}`}>
      <div className="box-cta-footer">
        <div className="icon-cta-footer">
          {typeCta === "contact" && <span><Iconcontactblue className="svg-icon-cta-footer"/></span>}
          {typeCta === "faq" && <span><Iconfaqblue className="svg-icon-cta-footer"/></span>}
          {typeCta === "learn" && <span><Iconlearnblue className="svg-icon-cta-footer"/></span>}
          {typeCta === "speak" && <span><Iconvcspeakdoctor className="svg-icon-cta-footer"/></span>}
        </div>
        <p className="title-level-1" dangerouslySetInnerHTML={{ __html:titleCta }} />
        {typeCta === "contact" && <Ctabutton ctaLabel={labelCta} ctaLink="/contact" otherStyle="bluemarin-format" />}
        {typeCta === "faq" && <Ctabutton ctaLabel={labelCta} ctaLink="/faq" otherStyle="bluemarin-format" />}
        {typeCta === "learn" && <Ctabutton ctaLabel={labelCta} ctaLink="/learning-center" otherStyle="bluemarin-format" />}
        {typeCta === "speak" && <Ctabutton ctaLabel={labelCta} ctaLink="/virtual-care/sign-up" otherStyle="bluemarin-format" />}
      </div>
    </section>
  )
}

/* Export function */
export default Sectionctafooter
