/**
 * Const with param element
 * Layout component
 * Display the main layout
 */
/* Import section */
import React from "react"
import PropTypes from "prop-types"
import Header from "./header"
import Footer from "./footer"
//import Iframefacebookpage from "./iframefacebookpage"
import "../styles/components/layout.scss"

/* Declaration function */
const Layout = ({ className, children }) => {
  return (
    <>
      <Header />

      <main className={className}>{children}</main>

      <Footer />

      {/*<div className="notification-bar">
        <Iframefacebookpage />
  </div> */}
    </>
  )
}

/* Function propTypes declaration */
Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

/* Export function */
export default Layout
